@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

* {
  margin: 0;
  padding: 0;
  list-style: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #fff;
  color: #3e3e3e;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  background: url("images/background-low.jpg") no-repeat center center;
  background-size: cover;
}
