.App,
#root,
body {
  width: 100%;
  height: 100%;
}
.App > .container {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.intro {
  top: 20px;
  background-color: #fff;
  padding: 1em;
  font-size: 11px;
}
.last-registerd-plate-fullscreen-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.last-registerd-plate-fullscreen-wrapper >.last-registerd-plate {
  text-align: center;
  width: 256.5px;
  top: 8px;
}
.last-registerd-plate-fullscreen-wrapper >.last-registerd-plate >.info {
  margin-bottom: 10px;
}
.last-registerd-plate-fullscreen-wrapper >.last-registerd-plate >.plate {
  width: 256.5px;
  height: 56.5px;
  line-height: 59px;
  background: url("images/license-plate-centered@2x.png") no-repeat center center;
  background-size: 256.5px 56.5px;
  padding-left: 14px;
  font-size: 40px;
  color: #d53a3d;
}

.social-share-buttons {
  display: inline-block;
}
.social-share-buttons > button {
  margin-left: 6px;
  margin-right: 6px;
}

.lastCheckedPlate {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 70px;
  font-size: 10px;
}
.lastCheckedPlate .info {
  color: #323957;
  font-size: 10px;
  background-color: #fff;
  padding: 10px;
}
.bottom-box {
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 10px;
}
.bottom-box .copyright {
  display: inline-block;
  color: #323957;
  font-size: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  width: 260px;
}

.bottom-box .info {
  display: inline-block;
  color: #323957;
  font-size: 10px;
  background-color: #fff;
  padding: 11px;
}

.bottom-box .copyright > a {
  text-decoration: none;
  color: #323957;
}

.bottom-box .copyright > a:hover {
  text-decoration: underline;
}


.bottom-box .copyright > a.contact {
  font-size: 9px;
}

.number-of-registered-plates-today, .last-checked-time {
  font-size: 10px;
  margin-top: 10px;
}

.external-link {
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.external-link > a {
  text-decoration: none;
  color: #d53a3d;
  border: 1px solid #d53a3d;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 256.5px;
  display: inline-block;
  text-align: center;
  font-size: 20px;
}

@media (min-width: 634px) {
  .intro {
    font-size: 12px;
  }
}
@media (min-width: 795px) {
  .intro {
    font-size: 14px;
  }
}